import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";

export const useNavigation = () => {
    const navigate = useNavigate();

    const navigateWithAnimation = (to) => {
        gsap.to([".container", ".footer"], {
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
                window.scrollTo(0, 0); // Scroll while the page is invisible
                navigate(to); // Change the route AFTER scrolling

                gsap.to([".container", ".footer"], { opacity: 1, duration: 0.5 }); // Fade-in effect
            },
        });
    };

    return navigateWithAnimation;
};

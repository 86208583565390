import { useState } from "react";
import styles from "./mailing.module.css";
import SideModal1 from "../sideModal1/sideModal1";
import mailingServices from "../../../api/mailingServices";

export default function AdminMailing() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());

        // Send the form data via the submitAd service.
        const result = await mailingServices.submitAd(data);
        if (result) {
            // If successful, close the modal.
            handleCloseModal();
        } else {
            alert("Error submitting ad. Please try again later.");
        }
    };

    return (
        <div>
            <div className={styles.header}>
                <h2 className="admin_route_heading">MAILING ROUTE</h2>
                <div className={styles.header_wrapper}>
                    <button className="admin__btn" onClick={handleOpenModal}>
                        Send Ad
                    </button>
                    <button className="admin__btn">Send Offer</button>
                </div>
            </div>
            <div>
                {isModalOpen && (
                    <SideModal1 onClose={handleCloseModal}>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles.formGroup}>
                                <label className="adin_label">Main Settings</label>
                                <div className={styles.mainSettings}>
                                    <select name="lang" className="admin_select">
                                        <option>EN</option>
                                        <option>BG</option>
                                    </select>
                                    <select name="currency" className="admin_select">
                                        <option>BGN</option>
                                        <option>EURO</option>
                                        <option>USD</option>
                                    </select>
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <label className="adin_label">General Information</label>
                                <div className={styles.generalInformation}>
                                    <input
                                        className="admin__input"
                                        placeholder="Name / Company"
                                        name="name"
                                        type="text"
                                    />
                                    <input
                                        className="admin__input"
                                        placeholder="Email"
                                        name="email"
                                        type="email"
                                    />
                                    <input
                                        className="admin__input"
                                        placeholder="Subject"
                                        name="subject"
                                        type="text"
                                    />
                                    <textarea
                                        className="admin__textarea"
                                        placeholder="Message"
                                        name="message"
                                    />
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <label className="adin_label">Web Dev</label>
                                <div className={styles.mainSettings}>
                                    <input
                                        className="admin__input"
                                        placeholder="Old Price"
                                        name="webDevOldPrice"
                                    />
                                    <input
                                        className="admin__input"
                                        placeholder="New Price"
                                        name="webDevNewPrice"
                                    />
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <label className="adin_label">Hosting</label>
                                <div className={styles.mainSettings}>
                                    <input
                                        className="admin__input"
                                        placeholder="Old Price"
                                        name="hostingOldPrice"
                                    />
                                    <input
                                        className="admin__input"
                                        placeholder="New Price"
                                        name="hostingNewPrice"
                                    />
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <label className="adin_label">Maintenance</label>
                                <div className={styles.mainSettings}>
                                    <input
                                        className="admin__input"
                                        placeholder="Old Price"
                                        name="maintenanceOldPrice"
                                    />
                                    <input
                                        className="admin__input"
                                        placeholder="New Price"
                                        name="maintenanceNewPrice"
                                    />
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <button
                                    type="button"
                                    className="admin__btn"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button type="submit" className="admin__btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </SideModal1>
                )}
            </div>
        </div>
    );
}

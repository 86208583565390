import { Outlet, NavLink } from "react-router-dom";
import styles from "./adminLayout.module.css";
import whiteLogo from "../../assets/white-logo.png";
import mailIcon from "../../assets/mailIcon.png";
import logoutIcon from "../../assets/logoutIcon.png";
import authServices from "../../api/authServices";

export default function AdminLayout() {
    const handleLogout = async () => {
        await authServices.adminLogout();
        window.location.reload();
    };

    return (
        <div className={styles.adminLayout}>
            <div className={styles.navbar}>
                <img className={styles.logo} src={whiteLogo} alt="Logo" />
                <div className={styles.navbarLinks}>
                    <NavLink
                        to="/admin/mailing"
                        className={({ isActive }) =>
                            isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                        }
                    >
                        <img src={mailIcon} alt="Mail Icon" />
                    </NavLink>
                </div>
                <button onClick={handleLogout} className={styles.link}>
                    <img src={logoutIcon} alt="Logout Icon" />
                </button>
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
}

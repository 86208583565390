import { useState, useEffect } from "react";
import styles from "./getOffer.module.css";
import mailingServices from "../../api/mailingServices";
import ThankYou from "../thankYou/thankYou"; // Import ThankYou component

export default function GetOffer() {
    const servicesList = [
        "Web Development",
        "UI/UX Design",
        "Graphic Design",
        "Digital Marketing",
        "Hosting",
        "Technical Support",
    ];

    // Step control
    const [step, setStep] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    // Selected services in Step 1
    const [selectedServices, setSelectedServices] = useState([]);

    // User info in Step 2
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        business: "",
        message: "",
    });

    // Scroll to top when submission is successful
    useEffect(() => {
        if (submitted) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [submitted]);

    // Scroll to top when moving from Step 1 to Step 2
    useEffect(() => {
        if (step === 2) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [step]);

    // Handle toggling services in Step 1
    const handleServiceClick = (service) => {
        if (selectedServices.includes(service)) {
            setSelectedServices(selectedServices.filter((s) => s !== service));
        } else {
            setSelectedServices([...selectedServices, service]);
        }
    };

    // Handle form input changes in Step 2
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requestData = {
            services: selectedServices,
            firstName: formData.name,
            lastName: "", // No last name field in the form
            email: formData.email,
            phone: formData.phone,
            idea: formData.message,
        };

        try {
            const response = await mailingServices.submitOfferRequest(requestData);
            if (response) {
                console.log("Request submitted successfully:", response);
                setSubmitted(true); // Switch to ThankYou component
            } else {
                throw new Error("Failed to submit request");
            }
        } catch (error) {
            alert("Something went wrong. Please try again.");
            console.error("Error submitting request:", error);
        } finally {
            setLoading(false);
        }
    };

    // Show Thank You message after successful submission
    if (submitted) {
        return <ThankYou text="Your request has been sent! We’ll be in touch soon to discuss further details." />;
    }

    return (
        <div className={styles.getOffer}>
            {/* STEP 1 */}
            {step === 1 && (
                <div className={`${styles.step} ${styles.step1}`}>
                    <h2>What services are you interested in?</h2>
                    <div className={styles.services}>
                        {servicesList.map((service) => (
                            <div
                                key={service}
                                className={styles.service}
                                onClick={() => handleServiceClick(service)}
                            >
                                <div className={styles.serviceName}>{service}</div>
                                <div
                                    className={
                                        selectedServices.includes(service)
                                            ? styles.selected
                                            : styles.unselected
                                    }
                                />
                            </div>
                        ))}
                    </div>

                    <div className={styles.buttons}>
                        <button
                            className="primary-btn"
                            onClick={() => setStep(2)}
                            disabled={selectedServices.length === 0}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            {/* STEP 2 */}
            {step === 2 && (
                <div className={`${styles.step} ${styles.step2}`}>
                    <h2>Tell us about you.</h2>

                    <form className={styles.formFields} onSubmit={handleSubmit}>
                        {/* Row 1: Name | Email */}
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Your Name</label>
                            <input
                                className="primary-input"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="email">Your Email</label>
                            <input
                                className="primary-input"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/* Row 2: Business | Phone */}
                        <div className={styles.formGroup}>
                            <label htmlFor="business">Your Business</label>
                            <input
                                className="primary-input"
                                id="business"
                                name="business"
                                type="text"
                                placeholder="Business Name"
                                value={formData.business}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="phone">Phone (optional)</label>
                            <input
                                className="primary-input"
                                id="phone"
                                name="phone"
                                type="tel"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Full-width row: Message */}
                        <div className={styles.formGroup}>
                            <label htmlFor="message">Your Message</label>
                            <textarea
                                className="primary-textarea"
                                id="message"
                                name="message"
                                placeholder="Briefly describe your project..."
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/* Full-width row: Buttons */}
                        <div className={styles.buttonRow}>
                            <button
                                type="button"
                                className="primary-btn"
                                onClick={() => setStep(1)}
                            >
                                Back
                            </button>
                            <button type="submit" className="primary-btn" disabled={loading}>
                                {loading ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}

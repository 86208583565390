import styles from './tools.module.css';
import figma from '../../assets/tools/figma.png';
import illustrator from '../../assets/tools/AI.png';
import afterEffects from '../../assets/tools/AE 1.png';
import lottie from '../../assets/tools/lottie.png';
import reactIcon from '../../assets/tools/react.png';
import nodejs from '../../assets/tools/nodeJS.png';
import mongodb from '../../assets/tools/mongo.png';
import stripe from '../../assets/tools/stripe.png';
import {useNavigation} from "../../services/navigationService";

export default function Tools() {
    const tools = [
        { name: 'Figma', image: figma, description: 'We use Figma for real-time, collaborative UI/UX design and prototyping. It allows our team to iterate quickly, share feedback effortlessly, and deliver polished interfaces that look and feel great on every device.' },
        { name: 'Illustrator', image: illustrator, description: 'Adobe Illustrator helps us create crisp, scalable vector graphics and icons. From logo concepts to detailed illustrations, it ensures our visual assets maintain perfect quality across all platforms.' },
        { name: 'After Effects', image: afterEffects, description: 'To add dynamic motion and bring visuals to life, we rely on After Effects. It’s our go-to tool for crafting engaging animations and motion graphics that elevate storytelling and user engagement.' },
        { name: 'Lottie', image: lottie, description: 'By integrating Lottie Files, we incorporate lightweight, high-quality animations into our digital experiences. This approach keeps websites and apps fast while still providing eye-catching, interactive elements.' },
        { name: 'React', image: reactIcon, description: 'We build modern, responsive front-end applications using React. Its component-based architecture allows us to develop highly interactive interfaces that are easy to maintain and scale.' },
        { name: 'NodeJS', image: nodejs, description: 'Node.js powers our server-side development, enabling fast, scalable, and efficient web applications. Its event-driven, non-blocking I/O model keeps our back-end running smoothly under heavy traffic.' },
        { name: 'MongoDB', image: mongodb, description: "For data storage and management, we rely on MongoDB’s flexible, document-based structure. It handles large amounts of information seamlessly, making our applications robust and responsive." },
        { name: 'Stripe', image: stripe, description: 'Stripe provides secure, user-friendly payment solutions for our clients’ online transactions. By integrating it into our projects, we ensure reliable and streamlined e-commerce experiences every time.' }
    ];

    const navigateWithAnimation = useNavigation();

    return (
        <div className={styles.tools}>
            <p className='main-p'>The tools we use</p>
            <h1 className={styles.toolsHeading}>What we use to craft your ideas.</h1>
            <div className={styles.toolsWrapper}>
                <div className={styles.toolsGrid}>
                    {tools.map((tool, index) => (
                        <div key={index} className={styles.tool}>
                            <div className={styles.toolHeading}>
                                <img src={tool.image} alt={tool.name} />
                                <h2>{tool.name}</h2>
                            </div>
                            <p className={`main-p ${styles.toolP}`}>{tool.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <section className={styles.toolsSecondary}>
                <h1>Couldn't find what you were looking for?</h1>
                <p className='main-p'>These are just a few of the tools we use. If you don’t see what you need, let us know—we’re happy to explore whatever helps you reach your goals.</p>
                <button onClick={() => {navigateWithAnimation('/contact')}} className={`primary-btn ${styles.fit}`}>Talk to us</button>
            </section>
        </div>
    );
}
import styles from './thankYou.module.css';
import thankYouImage from '../../assets/thankYou.png';
import {useNavigation} from "../../services/navigationService";

export default function ThankYou({ text }) {

    const navigateWithAnimation = useNavigation();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Thank You!</h1>
                <p className='main-p'>{text}</p>
                <button onClick={() => {navigateWithAnimation('/')}} className='primary-btn'>Go Home</button>
            </div>
            <img className={styles.img} src={thankYouImage} alt="Man smiling and holding a laptop"/>
        </div>
    )
}
import { useState, useEffect } from 'react';
import styles from './cookies.module.css';
import cookies from '../../assets/cookies.png';

export default function Cookies() {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('carica_cookie_consent');
        if (!cookieConsent) {
            setShowPopup(true);
        }
    }, []);

    const handleDismiss = () => {
        localStorage.setItem('carica_cookie_consent', 'accepted');
        setShowPopup(false);
    };

    return showPopup ? (
        <div className={styles.container}>
            <div className={styles.inner}>
                <img src={cookies} alt='Cookies icon' />
                <p className={styles.p}>We use cookies to enhance your experience. <br /> By continuing, you agree to our cookie policy.</p>
            </div>
            <button className='secondary-btn' onClick={handleDismiss}>Dismiss</button>
        </div>
    ) : null;
}
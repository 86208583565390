import axios from "axios";

const defaultLocalServerURL = "http://192.168.1.19:3003";
const localServerURL = process.env.REACT_APP_LOCAL_API_URL || defaultLocalServerURL;
if (process.env.NODE_ENV === "development" && !process.env.REACT_APP_LOCAL_SERVER_URL) {
    console.error(
        "REACT_APP_LOCAL_SERVER_URL is not defined. Falling back to default:",
        defaultLocalServerURL
    );
}

const productionServerURL = process.env.REACT_APP_PRODUCTION_SERVER_URL || "https://www.carica.website";
const baseURL =
    process.env.NODE_ENV === "development"
        ? `${localServerURL}/api`
        : `${productionServerURL}/api`;

console.log("Axios Base URL:", baseURL);

const http = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

http.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error("API Error:", error.response?.data || error.message);
        return Promise.reject(error);
    }
);

export default http;

import styles from "./footer.module.css";
import logo from "../../assets/white-logo.png";
import instagram from "../../assets/insta.svg";
import { useNavigation } from "../../services/navigationService";

export default function Footer() {
    const navigateWithAnimation = useNavigation();

    return (
        <footer className={`${styles.footer} footer`}> {/* Ensure it has the "footer" class */}
            <div className={styles.footerLeft}>
                <div className={styles.footerLeftTop}>
                    <img src={logo} alt="Carica Web footer logo" />
                    <h4>CARICA WEB</h4>
                    <p className={styles.footerColor}>
                        All your brand needs to stand out online, in one place.
                    </p>
                </div>
                <div>
                    <p className={styles.footerColor}>© 2025 Carica Ltd. - All rights reserved</p>
                </div>
            </div>
            <div className={styles.footerRight}>
                <div className={styles.footerTopRight}>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/")}>
                        Home
                    </button>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/about")}>
                        About
                    </button>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/tools")}>
                        Tools
                    </button>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/services")}>
                        Services
                    </button>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/contact")}>
                        Contact
                    </button>
                    <button className={styles.footerBtn} onClick={() => navigateWithAnimation("/get-offer")}>
                        Get Offer
                    </button>
                </div>
                <div className={styles.footerBottomRight}>
                    <button className={styles.footerSmallBtn} onClick={() => navigateWithAnimation("/terms")}>
                        Terms
                    </button>
                    <button className={styles.footerSmallBtn} onClick={() => navigateWithAnimation("/privacy-policy")}>
                        Privacy Policy
                    </button>
                    <img onClick={() => {
                        window.open("https://www.instagram.com/carica_web/", "_blank");
                    }} className={styles.insta} src={instagram} alt="Link to Instagram" />
                </div>
            </div>
        </footer>
    );
}

import http from "./http";

const authServices = {
    adminLogin: async (formData) => {
        try {
            const response = await http.post("/auth/admin/login", formData);
            return response.data;
        } catch (error) {
            console.error("adminLogin error:", error);
            return null;
        }
    },
    adminLogout: async () => {
        try {
            const response = await http.get("/auth/admin/logout");
            return response.data;
        } catch (error) {
            console.error("adminLogout error:", error);
            return null;
        }
    },
    adminVerifyToken: async () => {
        try {
            const response = await http.get("/auth/admin/validateToken");
            return response.data;
        } catch (error) {
            console.error("adminVerifyToken error:", error);
            return null;
        }
    }
};

export default authServices;
import React, { useState } from "react";
import authServices from "../../../api/authServices";
import styles from "./login.module.css";
import {useNavigation} from "../../../services/navigationService";

export default function AdminLogin() {
    const [formData, setFormData] = useState({ email: "", password: "" });

    const navigateWithAnimation = useNavigation();

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await authServices.adminLogin(formData);
        if (result) {
            navigateWithAnimation("/admin/dashboard");
        } else {
            alert("Invalid credentials");
        }
    };

    return (
        <div className={styles.container}>
            <h2>Welcome Back!</h2>
            <form className={styles.form} onSubmit={handleSubmit}>
                <input
                    className="admin__input"
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    className="admin__input"
                    placeholder="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <button type="submit" className="admin__btn">
                    Login
                </button>
            </form>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import authServices from "../api/authServices"; // adjust path if needed

function ProtectedAdminRoute() {
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        async function checkAdmin() {
            try {
                const response = await authServices.adminVerifyToken();
                if (response && response.message === "Token is valid") {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Error verifying admin token:", error);
            } finally {
                setLoading(false);
            }
        }
        checkAdmin();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!isAdmin) {
        return <Navigate to="/admin/login" replace />;
    }

    return <Outlet />;
}

export default ProtectedAdminRoute;

import http from "./http";

const mailingServices = {
    submitContactForm: async (formData) => {
        try {
            const response = await http.post("/mailing/submit-contact-form", formData);
            return response.data;
        } catch (error) {
            console.error("submitContactForm error:", error);
            return null;
        }
    },
    submitOfferRequest: async (formData) => {
        try {
            const response = await http.post("/mailing/submit-offer-request", formData);
            return response.data;
        } catch (error) {
            console.error("submitOfferRequest error:", error);
            return null;
        }
    },
    submitAd: async (formData) => {
        try {
            const response = await http.post("/mailing/admin/send-ad-email", formData);
            return response.data;
        } catch (error) {
            console.error("submitAd error:", error);
            return null;
        }
    }
}

export default mailingServices;
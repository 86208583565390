import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from "lottie-react";
import styles from "./home.module.css";
import mobileDrive from "../../assets/mobile_drive.png";

import toolsAnimation from "../../assets/lottie/toolsAnimation.json";
import webDevAnimation from "../../assets/lottie/webDev.json";
import uiUxAnimation from "../../assets/lottie/UI:UX.json";
import responsiveDesign from "../../assets/lottie/Responsive.json";
import graphicDesign from "../../assets/lottie/graphic_design.json";
import hosting from "../../assets/lottie/hosting.json";
import techSupport from "../../assets/lottie/tech_support.json";
import driveLottie from "../../assets/lottie/landing_drive.json";
import toolsMobile from "../../assets/landing_mobile.png";
import landingImage from "../../assets/landing.png";

import awsImg from "../../assets/Frame 34.png";
import stripeImg from "../../assets/Frame 37.png";
import hostingerImg from "../../assets/Frame 36.png";
import analyticsImg from "../../assets/Frame 35.png";

import ArrowBtn from "../../shared/arrowBtn/arrowBtn";
import { useNavigation } from "../../services/navigationService";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    const driveLottieRef = useRef(null);
    const navigateWithAnimation = useNavigation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 820);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const lottieInstance = driveLottieRef.current;
        if (lottieInstance) {
            ScrollTrigger.create({
                trigger: ".driveLottie",
                start: "top 80%",
                once: true,
                onEnter: () => {
                    lottieInstance.setSpeed(1);
                    lottieInstance.play();
                },
            });
        }
    }, []);

    return (
        <div className={styles.home}>
            <section className={styles.firstSection}>
                {isMobile ? (
                    <>
                        <div className={styles.firstSectionContent}>
                            <div className={styles.textContainer}>
                                <h2>
                                    Bring your business <span>Online.</span>
                                    <br />
                                    Everything in one place.
                                </h2>
                                <p className="main-p">
                                    From sleek websites to powerful online platforms, we handle everything to bring your business to life.
                                </p>
                            </div>
                            <div className={styles.btnsContainer}>
                                <button onClick={() => navigateWithAnimation("/get-offer")} className="primary-btn">
                                    Request Offer
                                </button>
                            </div>
                            <div className={styles.mobileImageWrapper}>
                                <img src={landingImage} alt="Landing" className={styles.landingImageMobile} />
                            </div>
                        </div>
                        <div className={styles.landingPartnersWrapper}>
                            <p>Powered by innovation:</p>
                            <div>
                                <img style={{ width: "38px", marginTop: "5px" }} src={awsImg} alt="AWS" />
                                <img style={{ width: "50px" }} src={stripeImg} alt="Stripe" />
                                <img style={{ width: "30px" }} src={hostingerImg} alt="Hostinger" />
                                <img style={{ width: "25px" }} src={analyticsImg} alt="Analytics" />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.firstSectionContent}>
                            <div className={styles.textContainer}>
                                <h2>
                                    Bring your business <span>Online.</span>
                                    <br />
                                    Everything in one place.
                                </h2>
                                <p className="main-p">
                                    From sleek websites to powerful online platforms, we handle everything to bring your business to life.
                                </p>
                            </div>
                            <div className={styles.btnsContainer}>
                                <button onClick={() => navigateWithAnimation("/get-offer")} className="primary-btn">
                                    Request Offer
                                </button>
                            </div>
                            <div className={styles.landingPartnersWrapper}>
                                <p>Powered by innovation:</p>
                                <div>
                                    <img style={{ width: "38px", marginTop: "5px" }} src={awsImg} alt="AWS" />
                                    <img style={{ width: "50px" }} src={stripeImg} alt="Stripe" />
                                    <img style={{ width: "30px" }} src={hostingerImg} alt="Hostinger" />
                                    <img style={{ width: "25px" }} src={analyticsImg} alt="Analytics" />
                                </div>
                            </div>
                        </div>
                        <img src={landingImage} alt="Landing" className={styles.landingImageDesktop} />
                    </>
                )}
            </section>

            <section className={styles.secondSection}>
                <div className={styles.secondSectionContent}>
                    <h2 className="center-text">
                        Modern Tools,
                        <br />
                        Modern Results
                    </h2>
                    <button onClick={() => navigateWithAnimation("/tools")} className="primary-btn primary-btn-exception">
                        Explore Tools
                    </button>
                </div>
                <div className={styles.animationContainer}>
                    {isMobile ? (
                        <img src={toolsMobile} alt="Tools Mobile" style={{ width: "100%", height: "auto" }} />
                    ) : (
                        <Lottie animationData={toolsAnimation} loop={true} style={{ width: "100%", height: "auto" }} />
                    )}
                </div>
            </section>

            <section className={styles.servicesSection}>
                <div className={styles.servicesContainer}>
                    <div className={styles.headingWrapper}>
                        <h2>Services We Provide</h2>
                        <ArrowBtn text="View all services" onClick={() => navigateWithAnimation("/services")} />
                    </div>
                    <div className={styles.services}>
                        <div className={styles.service}>
                            <div className={styles.webDevLottie}>
                                <Lottie animationData={webDevAnimation} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>Web Development</h3>
                                <p>
                                    We build everything from sleek static sites to robust e-commerce platforms, all designed to boost engagement and make your online presence shine.
                                </p>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.uiUxLottie}>
                                <Lottie animationData={uiUxAnimation} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>UI/UX Design</h3>
                                <p>
                                    We design interfaces that feel intuitive and look beautiful. Our focus is on creating user-friendly experiences that transform your ideas into digital realities.
                                </p>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.responsiveDesignLottie}>
                                <Lottie animationData={responsiveDesign} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>Responsive Design</h3>
                                <p>
                                    Our designs adapt to any device, ensuring a seamless user experience across all screens.
                                </p>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.graphicDesignLottie}>
                                <Lottie animationData={graphicDesign} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>Graphic Design</h3>
                                <p>
                                    We design custom icons and visuals that give your brand a unique, eye-catching identity.
                                </p>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.hostingLottie}>
                                <Lottie animationData={hosting} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>Hosting</h3>
                                <p>
                                    We take care of hosting the apps we build, so you never have to worry about keeping your website fast, secure, and online 24/7.
                                </p>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.techSupportLottie}>
                                <Lottie animationData={techSupport} loop={true} />
                            </div>
                            <div className={styles.serviceContent}>
                                <h3>Technical Support</h3>
                                <p>
                                    Our dedicated team is here to ensure minimal downtime. We provide ongoing support and train your staff to confidently use your app.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {isMobile && (
                <section className={styles.driveContentMobile}>
                    <div className={styles.driveHeadingWrapper}>
                        <h2>Carica Drive</h2>
                        <p className="main-p">
                            Connect your apps and access your files everywhere you go!
                        </p>
                    </div>
                    <p className={`main-p ${styles.soon}`}>Expect Soon!</p>
                </section>
            )}
            <section className={styles.driveSection}>
                {!isMobile && (
                    <div className={styles.driveContent}>
                        <div className={styles.driveHeadingWrapper}>
                            <h2 className="white-text">Carica Drive</h2>
                            <p className="main-p white-text">
                                Connect your apps and access your files everywhere you go!
                            </p>
                        </div>
                        <p className={styles.soon}>Expect Soon!</p>
                    </div>
                )}
                <div className={styles.driveLottie}>
                    {isMobile ? (
                        <img src={mobileDrive} alt="Carica Drive Mobile" className={styles.mobileDriveImage} />
                    ) : (
                        <Lottie lottieRef={driveLottieRef} animationData={driveLottie} loop={false} autoplay={false} />
                    )}
                </div>
            </section>

            <section className={styles.offerSection}>
                <h2>We’d Love to Hear About Your Business!</h2>
                <p className='main-p'>
                    Tell us a bit about what you do, and we’ll craft an offer that fits your needs perfectly.
                </p>
                <button onClick={() => navigateWithAnimation("/get-offer")} className="primary-btn">
                    Get Offer
                </button>
            </section>
        </div>
    );
}

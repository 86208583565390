import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { gsap } from "gsap";
import Home from "./features/home/home";
import Header from "./core/header/header";
import Footer from "./core/footer/footer";
import About from "./features/about/about";
import Tools from "./features/tools/tools";
import Services from "./features/services/services";
import Contact from "./features/contact/contact";
import GetOffer from "./features/getOffer/getOffer";
import Terms from "./features/terms/terms";
import Privacy from "./features/privacy/privacy";
import Cookies from "./core/cookiesConsent/cookies";
import HelmetManager from "./services/helmetManager";
import NotFound from "./features/404/404";
import AdminLogin from "./features/admin/login/login";
import AdminMailing from "./features/admin/mailing/mailing";
import AdminLayout from "./features/admin/adminLayout";

// NEW import for protected route
import ProtectedAdminRoute from "./auth/protectedAdminRoute";

function AppContent() {
    const location = useLocation();

    useEffect(() => {
        gsap.to(".container", { opacity: 1, duration: 0.5 });
    }, [location.pathname]);

    const isAdminRoute = location.pathname.startsWith("/admin");

    // Hide normal site header/footer if on /admin routes
    const showChrome = !isAdminRoute;

    return (
        <>
            {showChrome && <Header />}
            <div className={isAdminRoute ? "" : "container"}>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<><HelmetManager /><Home /></>} />
                    <Route path="/about" element={<><HelmetManager /><About /></>} />
                    <Route path="/tools" element={<><HelmetManager /><Tools /></>} />
                    <Route path="/services" element={<><HelmetManager /><Services /></>} />
                    <Route path="/contact" element={<><HelmetManager /><Contact /></>} />
                    <Route path="/get-offer" element={<><HelmetManager /><GetOffer /></>} />
                    <Route path="/terms" element={<><HelmetManager /><Terms /></>} />
                    <Route path="/privacy-policy" element={<><HelmetManager /><Privacy /></>} />

                    {/* Admin login is unprotected */}
                    <Route path="/admin/login" element={<AdminLogin />} />

                    {/* Everything else under /admin is protected */}
                    <Route path="/admin" element={<ProtectedAdminRoute />}>
                        {/* We nest AdminLayout so that it only shows if ProtectedAdminRoute passes */}
                        <Route element={<AdminLayout />}>
                            <Route path="dashboard" element={<AdminLayout />} />
                            <Route path="mailing" element={<AdminMailing />} />
                            {/* Add more protected admin routes here */}
                        </Route>
                    </Route>

                    {/* Catch-all for 404 */}
                    <Route path="*" element={<><HelmetManager /><NotFound /></>} />
                </Routes>
            </div>
            {showChrome && <Cookies />}
            {showChrome && <Footer />}
        </>
    );
}

function App() {
    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
}

export default App;

import { useState, useEffect } from "react";
import styles from "./contact.module.css";
import mailingServices from "../../api/mailingServices";
import ThankYou from "../thankYou/thankYou";

export default function Contact() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (submitted) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [submitted]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
            alert("Please fill in all required fields.");
            setLoading(false);
            return;
        }

        try {
            const response = await mailingServices.submitContactForm(formData);
            if (response) {
                console.log("Contact form submitted successfully:", response);
                setSubmitted(true);
            } else {
                throw new Error("Failed to submit the contact form");
            }
        } catch (error) {
            alert("Something went wrong. Please try again.");
            console.error("Error submitting contact form:", error);
        } finally {
            setLoading(false);
        }
    };

    return submitted ? (
        <ThankYou text="We appreciate your message! We'll get back to you as soon as possible." />
    ) : (
        <div className={styles.contact}>
            <p className="main-p">Contact Us</p>
            <h1>
                Love to hear from you, <br />
                Get in touch
            </h1>

            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.left}>
                    <label>First Name</label>
                    <input
                        className="primary-input"
                        type="text"
                        name="firstName"
                        placeholder="e.g., John"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                    />

                    <label>Last Name</label>
                    <input
                        className="primary-input"
                        type="text"
                        name="lastName"
                        placeholder="e.g., Doe"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                    />

                    <label>Email</label>
                    <input
                        className="primary-input"
                        type="email"
                        name="email"
                        placeholder="e.g., john.doe@example.com"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className={styles.right}>
                    <label>Phone (optional)</label>
                    <input
                        className="primary-input"
                        type="tel"
                        name="phone"
                        placeholder="e.g., +1 234 567 8900"
                        value={formData.phone}
                        onChange={handleInputChange}
                    />

                    <label>Your Message</label>
                    <textarea
                        className="primary-textarea"
                        name="message"
                        placeholder="e.g., I'd like more information about your services."
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                    ></textarea>

                    <button className={`primary-btn ${styles.fit}`} type="submit" disabled={loading}>
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
}
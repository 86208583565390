import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './about.module.css';
import PrimaryArrowButton from "../../shared/primaryArrowBtn/primaryArrowButton";
import { useNavigation } from "../../services/navigationService";
import kristiyan from '../../assets/kristiyan.png';
import erica from '../../assets/erica.png';
import { Helmet } from 'react-helmet';

export default function About() {
    const navigateWithAnimation = useNavigation();

    // First Section refs
    const sectionRef = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const borderRef = useRef(null);

    // Person Section Right refs
    const personRightSectionRef = useRef(null);
    const personRightImgRef = useRef(null);
    const personRightTextRef = useRef(null);

    // Person Section Left refs
    const personLeftSectionRef = useRef(null);
    const personLeftImgRef = useRef(null);
    const personLeftTextRef = useRef(null);

    // Our Mission Section refs
    const missionSectionRef = useRef(null);
    const missionHeadingRef = useRef(null);
    const missionTextRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();

        // First Section Animation
        // Mobile: left moves right and right moves left
        mm.add("(max-width: 768px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: "top top",
                    end: "bottom 50%",
                    scrub: true,
                },
            });
            tl.to(leftRef.current, { x: 50, opacity: 0, ease: "power1.out" }, 0)
                .to(rightRef.current, { x: -50, opacity: 0, ease: "power1.out" }, 0)
                .to(borderRef.current, { opacity: 0, ease: "power1.out" }, 0);
            return () => tl.kill();
        });

        // Desktop: vertical translation
        mm.add("(min-width: 769px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                },
            });
            tl.to(leftRef.current, { y: 50, opacity: 0, ease: "power1.out" }, 0)
                .to(rightRef.current, { y: -50, opacity: 0, ease: "power1.out" }, 0)
                .to(borderRef.current, { opacity: 0, ease: "power1.out" }, 0);
            return () => tl.kill();
        });

        // Person Section Right Animation
        // Desktop: image then text, and when scrolling down it remains (reverse only if scrolling up past trigger)
        mm.add("(min-width: 769px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: personRightSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none reverse",
                },
            });
            tl.from(personRightImgRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" })
                .from(personRightTextRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });
        // Mobile: text then image (and remain visible once played)
        mm.add("(max-width: 768px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: personRightSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none none",
                },
            });
            tl.from(personRightTextRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" })
                .from(personRightImgRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });

        // Person Section Left Animation
        // Desktop: image then text, with "play none none reverse"
        mm.add("(min-width: 769px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: personLeftSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none reverse",
                },
            });
            tl.from(personLeftImgRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" })
                .from(personLeftTextRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });
        // Mobile: text then image (remain visible once played)
        mm.add("(max-width: 768px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: personLeftSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none none",
                },
            });
            tl.from(personLeftTextRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" })
                .from(personLeftImgRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });

        // Our Mission Section Animation
        // Desktop
        mm.add("(min-width: 769px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: missionSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none reverse",
                },
            });
            tl.from(missionHeadingRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" })
                .from(missionTextRef.current, { y: 50, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });
        // Mobile – animation stays once played
        mm.add("(max-width: 768px)", () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: missionSectionRef.current,
                    start: "top 80%",
                    end: "bottom 60%",
                    toggleActions: "play none none none",
                },
            });
            tl.from(missionHeadingRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" })
                .from(missionTextRef.current, { y: 30, opacity: 0, duration: 1, ease: "power1.out" }, "-=0.5");
            return () => tl.kill();
        });

        return () => {
            mm.revert();
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div className={styles.about}>
            <section ref={sectionRef} className={styles.firstSection}>
                <div ref={leftRef} className={styles.firstSectionLeft}>
                    <p className="main-p">About Us</p>
                    <h2>Crafting Digital Experiences That Work for You.</h2>
                </div>
                <div ref={borderRef} className={styles.borderElement} />
                <div ref={rightRef} className={styles.firstSectionRight}>
                    <p className="main-p">
                        At Carica Web, we create custom websites and apps that bring your vision to life. We focus on designing digital spaces that help your business grow. Ready to create something amazing together?
                    </p>
                    <PrimaryArrowButton text="Let's Talk" onClick={() => navigateWithAnimation('/contact')} />
                </div>
            </section>

            <section ref={personRightSectionRef} className={styles.personSectionRight}>
                <img ref={personRightImgRef} src={kristiyan} alt="Kristiyan Lyubenov" />
                <div ref={personRightTextRef} className={styles.personSectionRightText}>
                    <p className={styles.role}>Founder & Web Developer</p>
                    <div className={styles.kristiyan}>
                        <h2>Kristiyan Lyubenov</h2>
                        <p className={`${styles.kristiyanBio} main-p`}>
                            Kristiyan is the driving force behind Carica Web, blending sleek, user-friendly design with cutting-edge technology. With a passion for building genuine connections, he ensures every project is both engaging and results-driven, helping businesses thrive online.
                        </p>
                    </div>
                </div>
            </section>

            <section ref={personLeftSectionRef} className={styles.personSectionLeft}>
                <div ref={personLeftTextRef} className={styles.personSectionLeftText}>
                    <p className={styles.role}>CCO & Graphic Designer</p>
                    <div className={styles.erica}>
                        <h2>Erica Torres</h2>
                        <p className={`${styles.kristiyanBio} main-p`}>
                            As Carica Web’s creative visionary, Erica translates brand stories into captivating visuals. She’s dedicated to delivering designs that not only look great but also reflect each client’s unique personality, ensuring every project resonates with authenticity and style.
                        </p>
                    </div>
                </div>
                <img ref={personLeftImgRef} src={erica} alt="Erica Torres" />
            </section>

            <section ref={missionSectionRef} className={styles.ourMission}>
                <h1 ref={missionHeadingRef}>Our Purpose</h1>
                <p ref={missionTextRef} className="main-p">
                    We’re committed to creating an environment where every conversation is meaningful. By listening closely and
                    responding with warmth and clarity, we turn interactions into relationships. Our purpose is to ensure that
                    every client feels valued and understood, transforming routine transactions into empowering, collaborative experiences.
                    <br />
                    In a world that often feels impersonal, we strive to stand out. We don’t just deliver results; we build trust,
                    inspire confidence, and make every step of your journey as enjoyable as it is effective.
                </p>
            </section>
        </div>
    );
}

import styles from './privacy.module.css';

export default function Privacy() {
    return (
        <div className={styles.privacy}>
            <h1>Privacy Policy</h1>

            <div className={styles.box}>
                <h3>1. Introduction</h3>
                <p className='main-p'>At <strong>Carica Web</strong>, we prioritize your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information.</p>
            </div>

            <div className={styles.box}>
                <h3>2. Information We Collect</h3>
                <p className='main-p'>We collect the following personal data:</p>
                <ul>
                    <li>Names, email addresses, and phone numbers</li>
                    <li>Payment history and transaction details</li>
                    <li>Billing addresses (for payment processing)</li>
                    <li>IP addresses and login activity</li>
                </ul>
            </div>

            <div className={styles.box}>
                <h3>3. How We Use Your Information</h3>
                <p className='main-p'>Your information is used for the following purposes:</p>
                <ul>
                    <li>Providing and maintaining our services</li>
                    <li>Processing payments and managing subscriptions</li>
                    <li>Customer support and communication</li>
                    <li>Enhancing security and fraud prevention</li>
                    <li>Complying with legal obligations</li>
                </ul>
            </div>

            <div className={styles.box}>
                <h3>4. Data Sharing & Third Parties</h3>
                <p className='main-p'><strong>Strict Policy:</strong> We do not share user data with third parties unless required by law.</p>
                <p className='main-p'>In cases of legal requests from government authorities, we may disclose necessary data to comply with applicable regulations.</p>
            </div>

            <div className={styles.box}>
                <h3>5. Security Measures</h3>
                <p className='main-p'>We implement strict security measures to protect user data, including:</p>
                <ul>
                    <li>Secure encryption for sensitive data</li>
                    <li>Access control to prevent unauthorized access</li>
                    <li>Regular security audits</li>
                </ul>
            </div>

            <div className={styles.box}>
                <h3>6. Data Retention</h3>
                <p className='main-p'>We store user data for as long as necessary to provide services and comply with legal obligations.</p>
                <p className='main-p'>Users can request the deletion of their personal data, subject to legal and operational requirements.</p>
            </div>

            <div className={styles.box}>
                <h3>7. User Rights</h3>
                <p className='main-p'>Users have the following rights regarding their data:</p>
                <ul>
                    <li>The right to access their personal data</li>
                    <li>The right to request corrections or deletions</li>
                    <li>The right to object to data processing</li>
                    <li>The right to withdraw consent for marketing communications</li>
                </ul>
            </div>

            <div className={styles.box}>
                <h3>8. Cookies & Tracking</h3>
                <p className='main-p'>Carica Web may use cookies to enhance user experience. Users can manage cookie preferences in their browser settings.</p>
            </div>

            <div className={styles.box}>
                <h3>9. Policy Updates</h3>
                <p className='main-p'>This Privacy Policy may be updated periodically. Users will be notified of significant changes.</p>
            </div>

            <div className={styles.box}>
                <h3>10. Contact Information</h3>
                <p className='main-p'>For any questions or concerns regarding this Privacy Policy, contact us at <strong>support@carica.website</strong>.</p>
            </div>
        </div>
    );
}

import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { Power2 } from "gsap";
import styles from "./header.module.css";
import logo from "../../assets/dark-logo.png";
import burgerIcon from "../../assets/mobileMenu.svg";
import { useNavigation } from "../../services/navigationService";

export default function Header() {
    const navigateWithAnimation = useNavigation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const menuRef = useRef(null);
    const overlayRef = useRef(null);
    const menuItems = useRef([]);
    const menuTimeline = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 650);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Initialize GSAP timeline when on mobile
    useEffect(() => {
        if (!isMobile) {
            // For desktop, clean up
            if (menuTimeline.current) {
                menuTimeline.current.kill();
                menuTimeline.current = null;
            }
            gsap.set(menuRef.current, { clearProps: "all" });
            gsap.set(overlayRef.current, { clearProps: "all" });
            gsap.set(menuItems.current, { clearProps: "all" });
            return;
        }

        // Set initial state for mobile elements
        gsap.set(menuRef.current, {
            display: "none",
            x: "100%",
            clipPath: "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)",
        });
        gsap.set(overlayRef.current, { display: "none", opacity: 0, pointerEvents: "none" });
        gsap.set(menuItems.current, { opacity: 0, y: 20 });

        if (!menuTimeline.current) {
            menuTimeline.current = gsap.timeline({
                paused: true,
                onStart: () => setIsAnimating(true),
                onComplete: () => setIsAnimating(false),
                onReverseComplete: () => {
                    setIsAnimating(false);
                    // Hide the elements after close animation finishes
                    gsap.set(overlayRef.current, { display: "none", pointerEvents: "none" });
                    gsap.set(menuRef.current, { display: "none" });
                },
            });

            menuTimeline.current
                // Make overlay visible
                .to(
                    overlayRef.current,
                    { display: "block", opacity: 1, pointerEvents: "auto", duration: 0.4 },
                    0
                )
                // Slide menu in from right
                .to(
                    menuRef.current,
                    { display: "block", x: 0, duration: 0.2, ease: Power2.easeInOut },
                    0
                )
                // Animate clipPath for a curved effect
                .to(
                    menuRef.current,
                    {
                        clipPath: "polygon(100% 0, 100% 0, 100% 100%, 0% 100%)",
                        duration: 0.3,
                        ease: Power2.easeOut,
                    },
                    0
                )
                .to(
                    menuRef.current,
                    {
                        clipPath: "polygon(40% 0, 100% 0, 100% 100%, 0% 100%)",
                        duration: 0.5,
                        ease: Power2.easeOut,
                    },
                    ">-0.1"
                )
                // Fade in menu items
                .to(
                    menuItems.current,
                    { opacity: 1, y: 0, duration: 0.3, stagger: 0.1 },
                    "-=0.2"
                );

            // Start timeline at 0 progress (closed state)
            menuTimeline.current.progress(0);
        }
    }, [isMobile]);

    // Listen for menuOpen changes and play or reverse the timeline
    useEffect(() => {
        if (!isMobile || !menuTimeline.current) return;
        if (menuOpen) {
            // Make sure the elements are visible before playing
            gsap.set(menuRef.current, { display: "block" });
            gsap.set(overlayRef.current, { display: "block" });
            menuTimeline.current.play();
        } else {
            menuTimeline.current.reverse();
        }
    }, [menuOpen, isMobile]);

    const handleOpen = () => {
        if (isAnimating || menuOpen) return;
        setMenuOpen(true);
    };

    const handleClose = () => {
        if (isAnimating || !menuOpen) return;
        setMenuOpen(false);
    };

    return (
        <>
            <header className={styles.mainHeader}>
                {/* Desktop Navigation */}
                {!isMobile ? (
                    <>
                        <div className={styles.partWrapper}>
                            <button onClick={() => navigateWithAnimation("/")} className={styles.cleanBtn}>
                                <p>Home</p>
                            </button>
                            <button onClick={() => navigateWithAnimation("/about")} className={styles.cleanBtn}>
                                <p>About</p>
                            </button>
                            <button onClick={() => navigateWithAnimation("/tools")} className={styles.cleanBtn}>
                                <p>Tools</p>
                            </button>
                        </div>
                        <img onClick={() => {navigateWithAnimation('/')}} className={styles.logoImg} src={logo} alt="Carica Web logo" />
                        <div className={styles.partWrapper}>
                            <button onClick={() => navigateWithAnimation("/services")} className={styles.cleanBtn}>
                                <p>Services</p>
                            </button>
                            <button onClick={() => navigateWithAnimation("/contact")} className={styles.cleanBtn}>
                                <p>Contact</p>
                            </button>
                            <button onClick={() => navigateWithAnimation("/get-offer")} className={styles.cleanBtn}>
                                <p>Get Offer</p>
                            </button>
                        </div>
                    </>
                ) : (
                    <div onClick={() => {navigateWithAnimation('/')}} className={styles.logoContainer}>
                        <img className={styles.logoImg} src={logo} alt="Carica Web logo" />
                        <p className={styles.companyName}>Carica Web</p>
                    </div>
                )}

                {/* Burger button appears only on mobile */}
                {isMobile && (
                    <button className={styles.burgerBtn} onClick={handleOpen}>
                        <img src={burgerIcon} alt="Menu" />
                    </button>
                )}
            </header>

            {/* Overlay */}
            <div
                ref={overlayRef}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0,0,0,0.3)",
                    zIndex: 99,
                }}
                onClick={handleClose}
            ></div>

            {/* Mobile Menu */}
            <nav ref={menuRef} className={styles.mobileMenu}>
                <button className={styles.closeBtn} onClick={handleClose}>
                    Close
                </button>
                <ul className={styles.menuList}>
                    <li
                        ref={(el) => (menuItems.current[0] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/");
                            handleClose();
                        }}
                    >
                        Home
                    </li>
                    <li
                        ref={(el) => (menuItems.current[1] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/about");
                            handleClose();
                        }}
                    >
                        About
                    </li>
                    <li
                        ref={(el) => (menuItems.current[2] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/tools");
                            handleClose();
                        }}
                    >
                        Tools
                    </li>
                    <li
                        ref={(el) => (menuItems.current[3] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/services");
                            handleClose();
                        }}
                    >
                        Services
                    </li>
                    <li
                        ref={(el) => (menuItems.current[4] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/contact");
                            handleClose();
                        }}
                    >
                        Contact
                    </li>
                    <li
                        ref={(el) => (menuItems.current[5] = el)}
                        className={styles.menuItem}
                        onClick={() => {
                            navigateWithAnimation("/get-offer");
                            handleClose();
                        }}
                    >
                        Get Offer
                    </li>
                </ul>
            </nav>
        </>
    );
}

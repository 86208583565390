import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";

const HelmetManager = () => {
    const location = useLocation();
    const [meta, setMeta] = useState({
        title: "Carica Web",
        description: "Your all-in-one digital solution—web development, UI/UX, hosting, and more.",
        keywords: "web development, UI/UX design, hosting, technical support, responsive design, graphic design",
        canonical: "https://carica.website/"
    });

    useLayoutEffect(() => {
        const metaMap = {
            "/": {
                title: "Carica Web - Bring Your Business Online",
                description: "Launch your business online with Carica Web—offering web development, UI/UX design, hosting, and tech support all in one place.",
                keywords: "web development, UI/UX design, hosting, technical support, responsive design, graphic design, Carica Drive",
                canonical: "https://carica.website/"
            },
            "/about": {
                title: "About Us - Carica Web",
                description: "Learn more about Carica Web, our mission, and the team behind your digital success.",
                keywords: "Carica Web, about us, web development, UI/UX design, digital solutions, business growth",
                canonical: "https://carica.website/about"
            },
            "/services": {
                title: "Our Services - Carica Web",
                description: "Explore Carica Web’s services, including web development, UI/UX design, hosting, and technical support.",
                keywords: "web services, Carica Web, website design, UI/UX, hosting, digital marketing",
                canonical: "https://carica.website/services"
            },
            "/tools": {
                title: "Tools & Technologies - Carica Web",
                description: "Discover the modern tools and technologies we use at Carica Web to build high-performance digital solutions.",
                keywords: "web development tools, Carica Web, React, GSAP, Figma, NodeJS, Lottie Animation, Lottie, Lottie Files, After Effects, Illustrator, MongoDB, Stripe, modern web solutions",
                canonical: "https://carica.website/tools"
            },
            "/contact": {
                title: "Contact Us - Carica Web",
                description: "Get in touch with Carica Web to discuss your project. Let’s bring your business online with our all-in-one web solutions.",
                keywords: "contact Carica Web, web development, business inquiries",
                canonical: "https://carica.website/contact"
            },
            "/get-offer": {
                title: "Get an Offer - Carica Web",
                description: "Tell us about your business and get a customized offer for web development, UI/UX design, hosting, and more.",
                keywords: "get offer, Carica Web pricing, website design cost",
                canonical: "https://carica.website/get-offer"
            },
            "/terms": {
                title: "Terms & Conditions - Carica Web",
                description: "Read the terms and conditions for using Carica Web’s services, including web development and hosting.",
                keywords: "terms and conditions, Carica Web policies",
                canonical: "https://carica.website/terms"
            },
            "/privacy-policy": {
                title: "Privacy Policy - Carica Web",
                description: "Learn how Carica Web handles your data and protects your privacy while using our web services.",
                keywords: "privacy policy, data protection, Carica Web security",
                canonical: "https://carica.website/privacy-policy"
            },
        };

        const defaultMeta = {
            title: "Carica Web",
            description: "Your all-in-one digital solution—web development, UI/UX, hosting, and more.",
            keywords: "web development, UI/UX design, hosting, responsive design, graphic design",
            canonical: "https://carica.website/"
        };

        setMeta(metaMap[location.pathname] || defaultMeta);
    }, [location.pathname]);

    return (
        <Helmet key={location.pathname}> {/* 🔥 Forces Helmet to re-render */}
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords} />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={meta.canonical} />
        </Helmet>
    );
};

export default HelmetManager;

import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styles from "./sideModal1.module.css";
import close from "../../../assets/close.png";

export default function SideModal1({ children, onClose }) {
    const modalRef = useRef();
    const overlayRef = useRef();

    useEffect(() => {
        // Prevent background scrolling.
        const originalOverflow = document.body.style.overflow;
        document.body.style.overflow = "hidden";

        // Animate overlay fade-in and modal slide-in concurrently.
        gsap.fromTo(
            overlayRef.current,
            { opacity: 0 },
            { opacity: 0.5, duration: 0.5, ease: "power2.out" }
        );
        gsap.fromTo(
            modalRef.current,
            { x: "100%" },
            { x: "0%", duration: 0.5, ease: "power2.out" }
        );

        return () => {
            document.body.style.overflow = originalOverflow;
        };
    }, []);

    const handleClose = () => {
        // Animate overlay fade-out and modal slide-out concurrently.
        gsap.timeline({
            onComplete: onClose,
        })
            .to(
                modalRef.current,
                { x: "100%", duration: 0.5, ease: "power2.in" },
                0
            )
            .to(
                overlayRef.current,
                { opacity: 0, duration: 0.5, ease: "power2.in" },
                0
            );
    };

    return (
        <div className={styles.modalWrapper}>
            <div
                className={styles.overlay}
                ref={overlayRef}
                onClick={handleClose}
            />
            <div className={styles.container} ref={modalRef}>
                <div className={styles.heading}>
                    <h2 className="admin_route_heading">Send Ad</h2>
                    <img
                        src={close}
                        alt="Close"
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
}

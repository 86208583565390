import styles from './services.module.css';
import webDev from '../../assets/services/webDev.png';
import uiUx from '../../assets/services/uiUx.png';
import responsive from '../../assets/services/responsive.png';
import graphicDesign from '../../assets/services/graphicDesign.png';
import hosting from '../../assets/services/hosting.png';
import techSupport from '../../assets/services/support.png';

export default function Services() {
    const servicesList = [
        {
            name: 'Web Development',
            image: webDev,
            introduction: 'We build custom websites—from sleek static sites to dynamic e-commerce platforms—that adapt flawlessly to every device.',
            theProcess: 'Using flexible grids and modern coding practices, we ensure each site is responsive and performs at its best.',
            theOutcome: "Enjoy a consistent, high-performance online presence that keeps users engaged, no matter the device or platform.",
        },
        {
            name: 'UI/UX Design',
            image: uiUx,
            introduction: 'We craft engaging interfaces that combine striking visuals with intuitive usability.',
            theProcess: 'Starting with user research, we design layouts and flows that are both beautiful and easy to use.',
            theOutcome: 'A seamless, engaging experience that boosts user engagement and satisfaction.',
        },
        {
            name: 'Responsive Design',
            image: responsive,
            introduction: 'Ensuring your site looks stunning on desktop, tablet, or smartphone.',
            theProcess: 'Flexible grids, dynamic layouts, and modern coding to adjust to any screen size.',
            theOutcome: 'A consistent, high-performance experience for every user, on any device.',
        },
        {
            name: 'Graphic Design',
            image: graphicDesign,
            introduction: 'Our graphic design services bring your brand’s story to life with eye-catching visuals.',
            theProcess: 'Combining creativity and strategy to produce compelling logos, icons, and marketing materials.',
            theOutcome: 'Designs that enhance your brand identity and communicate your message effectively.',
        },
        {
            name: 'Hosting',
            image: hosting,
            introduction: 'Friendly, reliable, and hassle-free hosting built for the apps we create.',
            theProcess: 'Our secure, cost-effective infrastructure handles all the heavy lifting—no technical headaches for you.',
            theOutcome: 'Enjoy peace of mind with a seamless hosting experience that keeps your app fast, safe, and online.',
        },
        {
            name: 'Technical Support',
            image: techSupport,
            introduction: 'We’re here to help with friendly and approachable support.',
            theProcess: 'When issues arise, we provide clear, straightforward solutions to get you back on track quickly.',
            theOutcome: 'Confidence that someone is always there to keep everything running smoothly and stress-free.',
        },
    ];

    return (
        <div className={styles.servicesContainer}>
            <p className="main-p">The tools we use</p>
            <h1 className={styles.servicesTitle}>What we use to craft your ideas.</h1>

            {servicesList.map((service, idx) => {
                const rowClass = idx % 2 === 0 ? styles.serviceRow : styles.serviceRowReverse;
                return (
                    <div className={rowClass} key={service.name}>
                        <div className={styles.textBlock}>
                            <h3>{service.name}</h3>
                            <div className={styles.contentBox}>
                                <p className={styles.sectionHeading}>Introduction</p>
                                <p className="main-p">{service.introduction}</p>
                            </div>
                            <div className={styles.contentBox}>
                                <p className={styles.sectionHeading}>The Process</p>
                                <p className="main-p">{service.theProcess}</p>
                            </div>
                            <div className={styles.contentBox}>
                                <p className={styles.sectionHeading}>The Outcome</p>
                                <p className="main-p">{service.theOutcome}</p>
                            </div>
                        </div>
                        <img
                            src={service.image}
                            alt={service.name}
                            className={styles.serviceImage}
                        />
                    </div>
                );
            })}
        </div>
    );
}
import styles from './terms.module.css';

export default function Terms() {
    return (
        <div className={styles.terms}>
            <h1>Terms of Usage</h1>

            <div className={styles.box}>
                <h3>1. Introduction</h3>
                <p className='main-p'>Welcome to <strong>Carica Web</strong>. By using our services, you agree to comply with the following Terms of Usage. If you do not agree, please do not use our services.</p>
            </div>

            <div className={styles.box}>
                <h3>2. Services Provided</h3>
                <p className='main-p'>Carica Web offers:</p>
                <ul>
                    <li>Web development</li>
                    <li>Graphic design</li>
                    <li>Technical support</li>
                    <li>Hosting (only for Carica Web applications)</li>
                    <li>Cloud storage via Carica Drive (integrated with AWS)</li>
                    <li>Subscription-based and one-time payment plans</li>
                </ul>
            </div>

            <div className={styles.box}>
                <h3>3. Eligibility & Account Registration</h3>
                <p className='main-p'>Our services are available to <strong>individuals, businesses, and developers</strong>. No age restrictions apply.</p>
                <p className='main-p'>To use <strong>Carica Drive</strong>, an account is required.</p>
            </div>

            <div className={styles.box}>
                <h3>4. Payments & Subscriptions</h3>
                <p className='main-p'><strong>All plans are paid only</strong>, with no free tier.</p>
                <p className='main-p'><strong>Subscriptions:</strong> Payments are processed exclusively through <strong>Stripe</strong>.</p>
                <p className='main-p'><strong>Regular services:</strong> Payments are accepted via <strong>bank transfer</strong>.</p>
                <p className='main-p'><strong>VAT:</strong> A 20% VAT applies to individual customers in Bulgaria. We are not part of the OSS scheme.</p>
            </div>

            <div className={styles.box}>
                <h3>5. Refund Policy</h3>
                <p className='main-p'><strong>No refunds</strong> unless there is a billing error on our part.</p>
                <p className='main-p'><strong>Prepaid services</strong> are non-refundable.</p>
            </div>

            <div className={styles.box}>
                <h3>6. User Content & Data Privacy</h3>
                <p className='main-p'><strong>Ownership:</strong> Users retain ownership of their uploaded content.</p>
                <p className='main-p'><strong>Deletion:</strong> We reserve the right to delete content if required by law or upon the user’s request.</p>
                <p className='main-p'><strong>Data Collected:</strong> We collect user information, including payment history, names, emails, phone numbers, and standard personal data for account management.</p>
                <p className='main-p'><strong>Privacy Policy:</strong> We do not share user data with third parties unless required by law.</p>
            </div>

            <div className={styles.box}>
                <h3>7. Account Suspension & Termination</h3>
                <p className='main-p'>We reserve the right to suspend or terminate accounts that <strong>violate our terms</strong>.</p>
                <p className='main-p'><strong>Banned users are not eligible for refunds.</strong></p>
            </div>

            <div className={styles.box}>
                <h3>8. Liability & Data Backups</h3>
                <p className='main-p'>Carica Web is <strong>not liable for data loss or service outages</strong>.</p>
                <p className='main-p'>While we maintain backups on <strong>AWS</strong>, users are advised to <strong>keep their own backups</strong> whenever possible.</p>
            </div>

            <div className={styles.box}>
                <h3>9. Amendments</h3>
                <p className='main-p'>Carica Web reserves the right to update these Terms of Usage at any time. Continued use of our services implies acceptance of any modifications.</p>
            </div>

            <div className={styles.box}>
                <h3>10. Contact</h3>
                <p className='main-p'>For inquiries regarding these terms, contact us at <strong>support@carica.website</strong>.</p>
            </div>
        </div>
    );
}